<template>
  <div class="flex">
    <div class="w-full overflow-x-auto bg-gray-200">
      <nav
        class="
          h-14
          flex
          items-center
          justify-between
          border-b border-black-200
          bg-white-100
          px-4
        "
      >
        <div>
          <button
            class="
            w-max
              border border-white-100
              bg-blue-500
              px-2
              rounded
              text-xs text-white-100
              py-2
              hover:pointer hover:bg-blue-600
            "
            @click="AllTasks"
          >
            <i class="fas fa-check-circle text-xs text-white-100 mr-1"></i> All
            tasks
          </button>
        </div>
        <!-- header for filters -->
        <form class="flex justify-end py-2">
          <multiselect
            class="
              border-0
              w-44
              mr-4
              rounded-lg
              text-gray-900 text-xs
              mb-0
              placeholder-mb-0
              select
              statusSelect
            "
            :options="statusOptions"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="true"
            :allow-empty="false"
            :searchable="true"
            :show-labels="false"
            placeholder="Select Status"
            name="taskStatus"
            id="status"
            track-by="id"
            label="name"
            @select="KsaveStatus"
            @input="filterTask()"
          >
          </multiselect>
        </form>
      </nav>
    
      <!-- Cards -->
      <!-- Header -->
      <div class="flex">
      <div class="flex justify-center px-4 sticky py-2">
        <div
          class="
            flex
            w-64
            justify-between
            items-center
            pl-8
            px-6
            py-2
            mx-2
            text-black-50
          "
          v-for="taskStatus in tasks"
          :key="taskStatus.id"
        >
          <h5
            class="
              text-lg
              font-medium
              tracking-tight
              text-black-50
              dark:text-white
            "
          >
            {{ taskStatus.name }}
          </h5>
          <i class="fas fa-plus"></i>
        </div>
      </div>
      </div>
      <div class="flex">
        <!-- List of Tasks -->
        <div class="pendingTask flex justify-center px-4">
          <div
            class="
              flex-1
              overflow-y-auto
              w-64
              mx-2
              rounded-lg
              dark:bg-gray-800 dark:border-gray-700
            "
            v-for="(task, index) in tasks"
            :key="index"
          >
            <!-- Backlog draggable component. Pass arrBackLog to list prop -->
            <draggable
              class="list-group kanban-column mx-2 rounded-md py-2 px-2"
              :list="tasks[index].task"
              group="tasks"
              :id="index"
              :move="detectMove"
              @change="changeIndex(index)"
              @end="end(index)"
            >
              <div
                v-for="(t, i) in task.task"
                :key="t.id"
                class="
                  text-sm text-black-50
                  bg-white-100
                  border border-gray-300
                  rounded-lg
                  py-4
                  px-3
                  mb-2
                  hover:border-gray-400
                  dark:bg-gray-700 dark:border-gray-600 dark:text-white
                "
                :id="i"
                @dblclick="showDetail(t)"
              >
                <div class="pb-7">
                  {{ t.name }}
                </div>
                <div class="flex flex-col justify-center">
                  <div>
                    <multiselect
                      class="
                        text-xs
                        border-0
                        mb-2
                        placeholder-mb-0
                        text-black-50
                        
                      "
                      :options="Project.member"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :preserve-search="true"
                      :allow-empty="false"
                      :searchable="true"
                      :show-labels="false"
                      placeholder=" Asignee"
                      name="userSelect"
                      id="user"
                      track-by="id"
                      label="name"
                      v-model="t.user"
                      @select="saveAssignTo"
                      @input="assignTaskTo(t.id, t.name)"
                    >
                    </multiselect>
                  </div>
                  <div class="">
                    <date-picker
                    style="width:100%"
                      class="text-xs "
                      valueType="YYYY-MM-DD"
                      type="date"
                      placeholder="deadline"
                      v-model="t.deadline"
                      @change="saveDeadline"
                      @close="updateTaskDeadline(t.id, t.name)"
                    >
                    </date-picker>
                  </div>
                </div>
              </div>

              <!-- New Input -->
              <div
                :id="'task-' + index"
                class="
                  hidden
                  text-sm text-black-50
                  bg-white-100
                  border border-gray-300
                  rounded-lg
                  py-4
                  px-3
                  mb-2
                  hover:border-gray-400
                  dark:bg-gray-700 dark:border-gray-600 dark:text-white
                "
              >
                <div class="pb-7">
                  <input
                    type="text"
                    placeholder="Add task.."
                    class="
                      task-inp
                      text-sm
                      placeholder-gray-400
                      rounded-md
                      border-gray-400
                      focus:ring-blue-500 focus:border-blue-500
                    "
                    id="taskName"
                    v-model="newTask.name"
                    @focusout="addNewTask(task.id, index)"
                    v-on:keyup.enter="addNewTask(task.id, index)"
                  />
                </div>
                <div class="justify-between">
                  <multiselect
                    class="text-xs border-0 placeholder-mb-0 text-black-50 mb-2"
                    :options="Project.member"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :allow-empty="false"
                    :searchable="true"
                    :show-labels="false"
                    placeholder=" Asignee"
                    track-by="id"
                    label="name"
                    name="userSelect"
                    id="user"
                    v-model="newTask.assignTo"
                  >
                  </multiselect>
                  <date-picker
                    class="text-xs outline-none pb-0"
                    valueType="format"
                    type="date"
                    placeholder="deadline"
                    v-model="newTask.deadline"
                  >
                  </date-picker>
                </div>
              </div>
            </draggable>

            <button
              class="
                w-full
                text-black-50
                font-medium
                bg-gray-200
                text-sm
                hover:bg-gray-300 hover:text-black-100
                py-2
                rounded-md
              "
              @click="addNew(index)"
            >
              <i class="fas fa-plus text-xs"></i> Add Task
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Task Detail Sidebar -->
    <div class="flex justify-end max-w-xl hidden fixed z-10 taskDetail right-0">
      <aside
        class="w-screen hidden border border-black-200 taskDetail"
        aria-label="Sidebar"
      >
        <div class="flex flex-col overflow-y-auto h-screen bg-white-100">
          <header
            class="
              header
              border-b border-black-200
              py-4
              px-2
              flex
              justify-end
              items-center
            "
          >
            <!-- <div class="dropdown">
              <span
                class="
                  bg-green-300
                  text-green-800 text-xs
                  font-semibold
                  mr-2
                  px-2.5
                  py-0.5
                  rounded-full
                  dark:bg-green-200 dark:text-pink-900
                "
                >Complete</span
              >
            </div> -->
            <div class="flex items-center">
              <div class="mr-4">
                <i
                  class="fas fa-paperclip text-black-50"
                  @click="toggleDocModal"
                ></i>
              </div>
              <nav class="flex pr-4" aria-label="Breadcrumb">
                <ol class="inline-flex items-center space-x-1 md:space-x-3">
                  <li class="inline-flex items-center">
                    <router-link
                      :to="{
                        name: 'KanbanDetail',
                        params: { taskId: this.detailTaskId },
                      }"
                      class="
                        inline-flex
                        pr-2
                        items-center
                        text-sm
                        font-medium
                        text-gray-700
                        hover:text-gray-900
                        dark:text-gray-400 dark:hover:text-white
                      "
                    >
                      Details
                    </router-link>
                    |
                  </li>
                  <li class="inline-flex items-center">
                    <router-link
                      :to="{
                        name: 'kanbanqastatus',
                        params: {
                          qaData: currentTaskQa,
                        },
                      }"
                      class="
                        inline-flex
                        items-center
                        text-sm
                        font-medium
                        text-gray-700
                        hover:text-gray-900
                        dark:text-gray-400 dark:hover:text-white
                      "
                    >
                      Q/A
                    </router-link>
                  </li>
                  <li class="inline-flex items-center">
                    |
                    <router-link
                      :to="{
                        name: 'kanbanreview',
                        params: {
                          reviewData: this.currentTaskReview,
                        },
                      }"
                      class="
                        inline-flex
                        pl-2
                        items-center
                        text-sm
                        font-medium
                        text-gray-700
                        hover:text-gray-900
                        dark:text-gray-400 dark:hover:text-white
                      "
                      @click.native="$event.stopImmediatePropagation()"
                    >
                      Review
                    </router-link>
                  </li>
                </ol>
              </nav>
              <button class="ml-3" @click="hideDetail">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </header>
          <main class="flex-1 overflow-y-auto">
            <router-view
              :default="{ name: 'KanbanDetail' }"
              @update-task-name="updataTaskName"
              @update-task-desc="updataTaskDescription"
              @update-comment="editCommentContent"
            >
            </router-view>
          </main>

          <!-- Comments -->
          <div class="py-1 px-5 space-x-2 rounded-b shadow-md">
            <div class="flex justify-end pt-16">
              <button
                class="p-2 bg-blue-500 text-white-100 font-bold rounded-lg"
                @click="addComment"
              >
                Comment
              </button>
            </div>
          </div>
        </div>
      </aside>
    </div>

    <!-- document Modal -->
    <div
      class="fixed z-10 overflow-y-auto top-0 w-full left-0 hidden"
      id="taskDocModal"
    >
      <div
        class="
          flex
          items-center
          justify-center
          min-height-100vh
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <!-- Backdrop -->
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <!-- Backdrop End -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"
          >&#8203;</span
        >

        <!-- Modal Body -->

        <div
          class="
            inline-block
            align-center
            bg-white
            rounded-lg
            text-left
            overflow-hidden
            shadow-xl
            transform
            transition-all
            sm:my-8 sm:align-middle sm:max-w-lg sm:w-full
          "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            class="
              flex
              justify-between
              items-center
              p-5
              rounded-t
              border-b
              dark:border-gray-600
            "
          >
            <h3 class="text-xl font-medium text-black-100 dark:text-white">
              Add Files
            </h3>
            <!-- Close button -->
            <button
              type="button"
              class="
                text-gray-400
                bg-transparent
                hover:bg-gray-200 hover:text-gray-900
                rounded-lg
                text-sm
                p-1.5
                ml-auto
                inline-flex
                items-center
                dark:hover:bg-gray-600 dark:hover:text-white
              "
              @click="toggleDocModal"
            >
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div class="p-6 space-y-6">
            <form class="space-y-6" @submit.prevent="addTaskDoc">
              <input
                class="
                  block
                  w-full
                  mb-5
                  text-sm text-gray-900
                  border border-gray-300
                  rounded-lg
                  cursor-pointer
                  bg-white-100
                  focus:outline-none
                "
                id="default_size"
                type="file"
                @change="mapFile"
              />
              <div class="flex items-center"></div>

              <div class="flex justify-center">
                <div class="flex items-center">
                  <button
                    type="submit"
                    class="
                      w-lg
                      text-white-100
                      bg-red-400
                      hover:bg-pink-50
                      focus:ring-4 focus:outline-none focus:ring-red-300
                      font-medium
                      rounded-lg
                      text-sm
                      px-5
                      py-2.5
                      text-center
                      dark:bg-red-400
                    "
                  >
                    Add
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import draggable from "vuedraggable";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "KanbanBoard",
  components: {
    draggable,
  },
  data() {
    return {
      newTask: {
        name: "",
        assignTo: "",
        priority: null,
        status: null,
        estimatedDuration: null,
        deadline: null,
      },
      content: "",
      statusOptions: [],
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["image", "code-block"],
      ],
      doc: {
        file: null,
      },
      comment: {
        description: "",
        id: "",
      },
      sidebarComp: false,
      currentTaskId: 0,
      detailTaskId: "",
      currentStatusId: 0,
      oldstatusId: 0,
      currentIndex: 0,
      currentAssignTo: "",
      currentDeadline: "",
      tasks: [],
      temp: "",
      currentTaskQa: {},
      currentTaskReview: {},
      kselectedSatus: "",
      kselectedPriority: "",
    };
  },
  computed: {
    ...mapGetters(["Project", "TaskPriority", "Task", "StatusId"]),
  },
  methods: {
    ...mapActions([
      "storeKanbanTask",
      "fetchPriority",
      "updateTask",
      "fetchTask",
      "storeTaskComment",
      "storeTaskDocument",
      "updateTaskComment",
      "updateKanbanTaskstatus",
      "getFilteredTasks",
    ]),
    ...mapMutations(["toggleLoading"]),

// Event for save status value
    KsaveStatus(item) {
      this.kselectedSatus = item;
    },
// Event for save priority value
    ksavePriority(item) {
      this.kselectedPriority = item;
    },

  // Task filters
    async filterTask() {
      this.toggleLoading(true);
      let newTasks = [];
      this.Project.task_status.find((el) => {
        if (el.id == this.kselectedSatus.id) {
          newTasks.push(el);
          this.tasks = newTasks;
        } else if (this.kselectedSatus.id == 0) {
          this.tasks = [];
          this.tasks = this.temp;
        }
        this.toggleLoading(false);
      });
    },

// All tasks
    AllTasks() {
      this.toggleLoading(true);
      (this.tasks = []), (this.tasks = this.temp);
      this.toggleLoading(false);
    },

    // for open and close document modal
    toggleDocModal() {
      document.getElementById("taskDocModal").classList.toggle("hidden");
    },
    mapFile(e) {
      this.doc.file = e.target.files[0];
    },
    // add Document
    async addTaskDoc() {
      this.toggleLoading(true);
      let formdata = new FormData();
      formdata.append("file", this.doc.file);
      formdata.append("task_id", this.$route.params.taskId);

      let res = await this.storeTaskDocument(formdata);
      this.toggleLoading(false);
      if (res.success) {
        this.$toasted.success(res.msg);
        this.doc.file = "";
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleDocModal();
    },
    //------comment --------
    async addComment() {
      this.toggleLoading(true);
      let res = await this.storeTaskComment({
        description: this.comment.description,
        task_id: this.$route.params.taskId,
      });
      this.toggleLoading(false);
      if (res.success) {
        this.$toasted.success(res.msg);
        this.comment.description = "";
      } else {
        this.$toasted.error(res.msg);
      }
    },
    //  Task Id
    changeIndex(index) {
      if (this.oldstatusId != this.tasks[index].id) {
        this.currentStatusId = this.tasks[index].id;
      }
    },

    // dragging tasks
    detectMove(item) {
      this.currentTaskId = item.draggedContext.element.id;
      this.oldstatusId = item.draggedContext.element.status_id;
    },

  // tasks droping event
    async end() {
      if (this.currentStatusId != 0) {
        this.toggleLoading(true);
        let res = await this.updateKanbanTaskstatus({
          task_id: this.currentTaskId,
          task_status_id: this.currentStatusId,
        });
        if (res.success) {
          this.$toasted.success(res.msg);
          this.currentTaskId = 0;
          this.oldstatusId = 0;
          this.currentStatusId = 0;
        } else {
          this.$toasted.error(res.msg);
        }
        this.toggleLoading(false);
      }
    },

    //Update task asignee
    async assignTaskTo(id, name) {
      if (this.Project.is_add_task) {
        this.toggleLoading(true);
        let res = await this.updateTask({
          id: id,
          name: name,
          assign_to: this.currentAssignTo.id,
        });
        if (res.success) {
          this.$toasted.success(res.msg);
        } else {
          this.$toasted.error(res.msg);
        }
        this.toggleLoading(false);
      } else {
        this.$toasted.error("You dont have permission to assign task!");
      }
    },

    // Update task deadline
    async updateTaskDeadline(id, name) {
      this.toggleLoading(true);
      let res = await this.updateTask({
        id: id,
        name: name,
        deadline: this.currentDeadline,
      });
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    },

    // Event for add new task
    async addNewTask(statusId, index) {
      if (this.newTask.name.length > 0) {
        this.toggleLoading(true);
        let res = await this.storeKanbanTask({
          name: this.newTask.name,
          priority: this.newTask.priority.id,
          status_id: statusId,
          project_id: this.Project.id,
          assign_to: this.newTask.assignTo.id,
        });

        if (res.success) {
          this.$toasted.success(res.msg);

          this.tasks[this.currentIndex].task.push(res.data);
          this.newTask.name = "";
          this.newTask.priority = "";
          this.newTask.status = "";
        } else {
          this.$toasted.error(res.msg);
        }
        this.toggleLoading(false);
      } else {
        this.currentIndex = 0;
      }
      $(`#task-${index}`).addClass("hidden");
    },
    addNew(index) {
      // input focus
      $(`#task-${index}`).focus();
      this.currentIndex = index;
      this.newTask.priority = this.TaskPriority.find(
        (el) => el.slug == "medium"
      );
      this.newTask.assignTo = JSON.parse(localStorage.getItem("user"));
      this.newTask.status = index;

      $(`#task-${index}`).removeClass("hidden");
    },
    // Asignee NAme
    saveAssignTo(item) {
      this.currentAssignTo = item;
    },

    // Task deadline
    saveDeadline(item) {
      this.currentDeadline = item;
    },

    //update task name
    async updataTaskName(name, id) {
      this.toggleLoading(true);
      let res = await this.updateTask({
        id: id,
        name: name,
      });
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    },
    //update task description
    async updataTaskDescription(desc, id, name) {
      this.toggleLoading(true);
      let res = await this.updateTask({
        id: id,
        name: name,
        description: desc,
      });
      this.toggleLoading(false);
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
    },
    // edit Comment
    editCommentContent(comment) {
      this.comment.description = comment.description;
      this.comment.id = comment.id;
      this.content = comment.description;
      this.showAddBtn = false;
      this.showUpdateBtn = true;
    },

    // for current qa task, for qa status
    currentQa() {
      if (this.Task.task_qa.length != 0) {
        let taskQa = this.Task.task_qa;
        let currentTaskQa = {};
        taskQa.forEach((element) => {
          if (element.end_time == null) {
            currentTaskQa = element;
          }
        });
        this.currentTaskQa = currentTaskQa;
      }
    },

    // For current review task, Review status
    currentReview() {
      if (this.Task.task_review.length != 0) {
        let taskReview = this.Task.task_review;
        let currentTaskReview = {};
        taskReview.forEach((element) => {
          if (element.end_time == null) {
            currentTaskReview = element;
          }
        });
        this.currentTaskReview = currentTaskReview;
      }
    },

    // Task Detail sidebar
    async showDetail(t) {
      this.detailTaskId = t.id;
      let id = t.id;
      this.sidebarComp = true;
      this.$router.push({
        name: "KanbanDetail",
        params: { taskId: this.detailTaskId },
      });
      this.toggleLoading(true);
      await this.fetchTask({ id });
      let sidebar = $(".taskDetail");
      if (sidebar.hasClass("hidden")) {
        sidebar.removeClass("hidden");
        this.toggleLoading(false);
      }
      this.currentQa();
      this.currentReview();
    },
    hideDetail() {
      let stateObj = { id: "22" };
      window.history.replaceState(
        stateObj,
        "List",
        `/project/${this.$route.params.id}/kanbanBoard`
      );
      $(".taskDetail").addClass("hidden");
      this.sidebarComp = false;
    },
  },
  created() {
    this.temp = this.Project.task_status;
    this.temp.sort((a, b) => {
      return a.order - b.order;
    });
    this.tasks = this.temp;
    this.fetchPriority();
    this.Project.task_status.find((el) => {
      this.statusOptions.push(el);
    });
  },
  mounted() {
    if (this.sidebarComp == true) {
      $(".taskDetail").removeClass("hidden");
    }
    let url = window.location.href;
    let segment = url.split("/").pop();
    if (segment == "") {
      $(".taskDetail").removeClass("hidden");
    }
    if (segment == "kanbanreview") {
      $(".taskDetail").removeClass("hidden");
    }
    if (segment == "kanbanqastatus") {
      $(".taskDetail").removeClass("hidden");
    }
  },
};
</script>

<style scoped>

</style>